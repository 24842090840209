<template>
  <div class="today-reading">
    <div class="title">今日导读</div>
    <div class="list">
      <div
        class="item"
        v-for="(it, index) in list"
        :key="index"
        @click="handleArticle(it)"
      >
        <a>
          {{ it.title }}
        </a>
        <div>{{ it.publishTime }}</div>
      </div>
    </div>
    <div class="bottom-pagination" v-if="!$route.query.searchData">
      <a-pagination
        :total="total"
        size="small"
        v-model="queryParams.pageNum"
        show-quick-jumper
        :defaultPageSize="queryParams.pageSize"
        @change="onChangePagination"
      />
    </div>
  </div>
</template>

<script>
import { getTodayRead } from '@/api/article'
export default {
  props: {
    categoryId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      list: [],
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        categoryId: ''
      },
      total: 0
    }
  },
  watch: {
    categoryId(value) {
      if (value) {
        this.queryParams = {
          pageSize: 10,
          pageNum: 1
        }
        this.getList()
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const params = {
        ...this.queryParams,
        categoryId: this.categoryId
      }
      const { data, total } = await getTodayRead(params)
      this.total = total
      this.list = [...data]
    },
    handleArticle(item) {
      this.$router.push({
        path: '/detail',
        query: {
          articleId: item.articleId
        }
      })
    },
    onChangePagination(pageNum, pageSize) {
      this.queryParams.pageNum = pageNum
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.today-reading {
  overflow: hidden;
  margin-bottom: 36px;
  position: relative;
  width: 760px;
  .title {
    width: 120px;
    height: 62px;
    background: url('~@/assets/img/fontSize-bg.png') no-repeat;
    background-size: contain;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 42px;
    letter-spacing: 1px;
    text-align: center;
  }
  .list {
    width: 100%;
    min-height: 65px;
    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    a {
      width: 60%;
      display: inline-block;
      margin: 4px 0;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #212223;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      &:hover {
        color: #5e7ed4;
      }
    }
  }
  .bottom-pagination {
    position: absolute;
    bottom: 0px;
    right: 0;
    text-align: right;
    // padding-right: 16px;
  }
}
</style>
