<template>
  <div class="tabs-pages">
    <div class="tabs">
      <div class="tabs-top">
        <!--  -->
        <div
          class="moulde"
          v-if="article.surfacePlot"
          @click="handleDetail(article.articleId)"
        >
          <div class="img-content" v-if="article.surfacePlot">
            <img v-if="article.surfacePlot" :src="article.surfacePlot" alt="" />
            <span class="img-name">
              {{ article.title }}
            </span>
          </div>
        </div>
        <!--  -->
        <TodayRading
          class="mr-50"
          :data="todayRead"
          :categoryId="categoryId"
        ></TodayRading>
      </div>
      <div class="tabs-bottom">
        <ArticleListTab
          v-for="item in articleList"
          :data="item"
          :key="item.articleId"
          class="mr-100"
        ></ArticleListTab>
        <!-- <ArticleListTab class="mr-100"></ArticleListTab> -->
        <div class="article-list" v-if="categoryId == 1015">
          <h3 @click="handleTitle" v-if="list">
            {{ this.categoryName != '' ? this.categoryName : '机构展示' }}
            <span class="more">更多>></span>
          </h3>
          <div class="list">
            <div>
              <div
                class="text normall"
                v-for="item in list"
                :key="item.orgId"
                @click="handleArticle(item)"
              >
                <span class="text-title">{{ item.name }}</span>
                <span class="text-time">地址：{{ item.address }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 友情链接 -->
    <Blogroll></Blogroll>
  </div>
</template>

<script>
import ArticleListTab from '@/components/ArticleMoule/ArticleListTab'
import TodayRading from '@/components/TodayRading'
import Blogroll from '@/components/Blogroll'
import { queryCategory, getTodayRead } from '@/api/article'
import { getOrganizationList } from '@/api'
export default {
  components: {
    ArticleListTab,
    TodayRading,
    Blogroll
  },
  data() {
    return {
      list: [],
      todayRead: [],
      articleList: [],
      articleList1: [],
      article: {},
      categoryName: ''
    }
  },
  created() {
    this.getList()
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(val) {
        this.categoryId = val.categoryId
        this.getArticleList()
      }
    }
  },
  methods: {
    handleArticle(it) {
      this.$router.push({
        path: '/organizationDetail',
        query: {
          data: JSON.stringify(it)
        }
      })
    },
    async getList() {
      const params = {
        pageNum: 1, //页码
        pageSize: 5, //每页条数
        areaCode: '',
        area: '',
        city: ''
      }
      const { data, code } = await getOrganizationList(params)

      try {
        if (code === 200) {
          this.list = [...data]
          console.log(this.list)
        }
      } catch (error) {
        // this.$message.error(error)
      }
    },
    handleTitle() {
      this.$router.push({
        path: '/organizationList'
      })
    },
    async getArticleList() {
      const { data, code } = await queryCategory(this.categoryId)
      const params = {
        pageSize: 9999,
        pageNum: 1,
        categoryId: this.categoryId
      }
      const res = await getTodayRead(params)
      this.article = res.data.find(item => item.surfacePlot != '')
      // try {
      if (code === 200) {
        this.todayRead = data.todayRead
        this.articleList = Object.entries(data.children).filter(
          item => item[0] != 1055
        )
        this.articleList1 = Object.entries(data.children).filter(
          item => item[0] == 1055
        )
        if (this.articleList1 && this.articleList1[0]) {
          this.categoryName = this.articleList1[0][1][1].categoryName
        }
      }
      // } catch (error) {
      //   this.$message.error(error)
      // }
    },
    handleDetail(articleId) {
      this.$router.push({
        path: '/detail',
        query: {
          articleId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs-pages {
  padding: 0 160px;
  .tabs {
    margin: 40px 0;
    .tabs-top {
      display: flex;
      justify-content: flex-start;
      .moulde {
        width: 800px;
        height: 446px;
        margin: 16px 0 52px;
        .img-content {
          position: relative;
          cursor: pointer;
          > img {
            width: 800px;
            height: 446px;
            object-fit: cover;
            background: #d8d8d8;
          }
          .img-name {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 800px;
            height: 58px;
            background: rgba(0, 0, 0, 0.1);
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 58px;
            padding-left: 15px;
          }
        }
      }
    }
    .tabs-bottom {
      display: flex;
      flex-wrap: wrap;
      // justify-content: flex-start;
      justify-content: space-between;
      // width: 740px;
    }
  }
  .mr-50 {
    margin-left: 50px;
  }
  .mr-100 {
    margin-bottom: 40px;
    &:nth-child(2n) {
      // margin-left: 77px;
    }
  }
}
.article-list {
  border-top: 2px solid #eeeeee;
  width: 748px;
  position: relative;
  height: 280px;
  overflow: hidden;
  > h3 {
    border-top: 2px solid #1766aa;
    font-size: 30px;
    padding: 20px 0;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff4d4d;
    line-height: 30px;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    // &:hover {
    //   color: #5e7ed4;
    // }
  }
  .more {
    position: absolute;
    right: 0;
    &:hover {
      color: #5e7ed4;
    }
  }
  .list {
    .text {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .text-title {
        width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          color: #5e7ed4;
        }
      }
    }
    .title {
      height: 30px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #212223;
      line-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        color: #5e7ed4;
      }
    }

    .normall {
      position: relative;
      width: 100%;
      height: 22px;
      margin: 10px 0;
      padding-left: 12px;
      font-size: 16px;
      font-weight: 400;
      color: #212223;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        color: #5e7ed4;
      }
    }
    .normall::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 8px;
      width: 6px;
      height: 6px;
      background: #d8d8d8;
      border-radius: 50%;
    }
  }
}
</style>
