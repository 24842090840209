<template>
  <div class="article-list">
    <h3 @click="handleTitle" v-if="list">
      {{ list[0].categoryName }}
      <span class="more">更多>></span>
    </h3>
    <div class="list">
      <div>
        <div
          class="text"
          :class="[item.frontFlag == 0 ? 'title' : 'normall']"
          v-for="item in list"
          :key="item.articleId"
          @click="handleArticle(item)"
        >
          <span class="text-title">{{ item.title }}</span>
          <span class="text-time">{{ item.publishTime }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    titleId() {
      return this.data[0]
    },
    list() {
      return this.data[1]
    }
  },
  methods: {
    ...mapMutations({
      setLayoutTitle: 'layout/setLayoutTitle'
    }),
    handleTitle() {
      this.$router.push({
        path: '/listpage',
        query: {
          categoryId: this.titleId
        }
      })
      this.setLayoutTitle(this.list[0].categoryName)
    },
    handleArticle(item) {
      this.$router.push({
        path: '/detail',
        query: {
          articleId: item.articleId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.article-list {
  border-top: 2px solid #eeeeee;
  width: 748px;
  position: relative;
  height: 280px;
  overflow: hidden;
  > h3 {
    border-top: 2px solid #1766aa;
    font-size: 30px;
    padding: 20px 0;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff4d4d;
    line-height: 30px;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    // &:hover {
    //   color: #5e7ed4;
    // }
  }
  .more {
    position: absolute;
    right: 0;
    &:hover {
      color: #5e7ed4;
    }
  }
  .list {
    .text {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .text-title {
        width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          color: #5e7ed4;
        }
      }
    }
    .title {
      height: 30px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #212223;
      line-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        color: #5e7ed4;
      }
    }

    .normall {
      position: relative;
      width: 100%;
      height: 22px;
      margin: 10px 0;
      padding-left: 12px;
      font-size: 16px;
      font-weight: 400;
      color: #212223;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        color: #5e7ed4;
      }
    }
    .normall::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 8px;
      width: 6px;
      height: 6px;
      background: #d8d8d8;
      border-radius: 50%;
    }
  }
}
</style>
